import React from 'react';
import {Route, Routes, BrowserRouter} from 'react-router-dom';
import './App.css';
import MainLayout from "./components/MainLayout";
import Home from "./components/Home";
import Chat from "./components/Chat";
import Roadmap from "./components/Roadmap";
import Whitepaper from "./components/Whitepaper";
import Team from "./components/Team";

function App() {
  return (
      <BrowserRouter>
        <div className="App bg">
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route index element={<Home />}></Route>
              <Route path="chat" element={<Chat />}></Route>
              <Route path="whitepaper" element={<Whitepaper />}></Route>
              <Route path="roadmap" element={<Roadmap />}></Route>
              <Route path="team" element={<Team />}></Route>
              <Route path="*" element=""></Route>
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
  );
}

export default App;