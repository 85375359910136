import React, { Suspense, useRef, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, OrbitControls, Environment } from '@react-three/drei';
import * as THREE from 'three';

const RotatingObject = () => {
    const meshRef = useRef(); // Ссылка на весь объект

    // Загружаем модель
    const { scene } = useGLTF('/models/R5.glb');

    useEffect(() => {
        if (scene) {
            scene.traverse((child) => {
                if (child.isMesh) {
                    child.material = new THREE.MeshStandardMaterial({ color: 0xff0000 }); // Красный цвет
                }
            });
        }
    }, [scene]);

    // Вращение всего объекта
    useFrame(() => {
        if (meshRef.current) {
            meshRef.current.rotation.y += 0.01; // Вращение всего объекта вокруг оси Y
        }
    });

    return (
        <primitive
            ref={meshRef}
            object={scene}
            scale={0.16}
            position={[0, -2, 0]}
            rotation={[0.3, 0, 0]} // Угол наклона по оси X
        />
    );
};

const App = () => {
    return (
        <Canvas style={{ width: '100%', height: '80vh' }}>
            <pointLight position={[0, 0, 0]} intensity={1.5} />
            <hemisphereLight color={0xffffbb} groundColor={0x080820} intensity={0.1} />
            <directionalLight position={[-5, 10, -5]} intensity={1.2} />
            <Suspense fallback={null}>
                <RotatingObject />
                <Environment preset="night" />
            </Suspense>
        </Canvas>
    );
};

export default App;