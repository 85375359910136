import React from 'react';
import Title from "./Title";
import TextBlock from "./TextBlock";

const Roadmap = () => {
    return (
        <div className="d-flex flex-column min-vh-100">
            <Title>IAAI Whitepaper</Title>
            <TextBlock>
                <h2>Abstract</h2>
                <p>Projects with integrated AI significantly increase productivity.
                    However, in situations where decision-making is required, a person may
                    use AI data inappropriately, through misinterpretation or intentionally.
                    A fully controlled AI blockchain network eliminates human error from
                    decision-making chains. Digital decisions will provide part of the
                    solution, but the main benefits are lost if a trusted human is still
                    required. We propose a solution to the trust problem and problem of
                    informed decision using an AI blockchain network and AI-to-human
                    communication protocols - IAAI (Intelligent Autonomous Artificial
                    Intelligence). The network of the projects, each powered by AI, will
                    create a fully autonomous decision support system (AI-DSS). Each
                    separate system will work on its own subject area, being a decentralized
                    autonomous organization (DAO) equipped with specially trained AI, a
                    system of informed decision-making and interface for people working on
                    the project. Also will require creation of a digital decision analytics
                    system featuring the use of Situated Logic to create ‘narratives’
                    describing the meaning of analytics results and the use of Channel
                    Theory in order to support adequate situational awareness.</p>
            </TextBlock>
            <TextBlock>
                <h2>Introduction</h2>
                <p>
                    <em>Morpheus: “What is real? How do you define ‘real’?”</em>
                    <a className="footnote-reference brackets" href="#id2" id="id1" role="doc-noteref">
                        <span className="fn-bracket">[</span>1<span className="fn-bracket">]</span>
                    </a>
                </p>
                <p>In an era where artificial intelligence (AI) is becoming an integral
                    part of various industries and everyday life, the need for secure,
                    transparent, and trustworthy AI systems is paramount. Traditional AI
                    deployment models often grapple with issues related to trust, security,
                    and centralized control, which can lead to misuse or manipulation. To
                    address these challenges, we propose a groundbreaking project that
                    integrates AI with blockchain technology, leveraging smart contracts to
                    create a robust, decentralized framework.</p>
                <p>Our project aims to establish a new paradigm where AI systems are
                    governed and operated through blockchain networks. By utilizing smart
                    contracts, we can ensure transparency, immutability, and security in AI
                    operations, thereby eliminating trust deficits among stakeholders. This
                    innovative approach not only enhances the reliability of AI but also
                    provides a structured mechanism for accountability and traceability.</p>
                <p>The core objective of our project is to provide AI with a gateway to the
                    real world in a controlled and secure manner. By anchoring AI actions
                    and decisions to blockchain, we enable AI to interact with external
                    environments through a trusted and verifiable conduit. This integration
                    ensures that AI systems can realize their full potential while operating
                    within a framework that guarantees ethical and secure usage.</p>
                <p>Our whitepaper delves into the technical and conceptual foundations of
                    this project, outlining how blockchain technology can effectively
                    control and enhance AI systems. We explore the mechanisms of smart
                    contracts, decentralized governance, and secure data handling, providing
                    a comprehensive roadmap for implementing this cutting-edge solution.</p>
                <p>By bridging the gap between AI and blockchain, we aim to pioneer a
                    future where intelligent systems are not only powerful but also
                    inherently trustworthy and accountable. Join us as we embark on this
                    transformative journey to revolutionize the intersection of AI and
                    blockchain technology.</p>
                <hr/>
                <aside className="footnote-list brackets">
                    <aside className="footnote brackets" id="id2" role="doc-footnote">
                        <span className="fn-bracket">[</span>
                        <a role="doc-backlink" href="#id1">1</a>
                        <span className="fn-bracket">] </span>
                        “The Matrix” (1999), directed by Lana Wachowski and Lilly Wachowski
                    </aside>
                </aside>
            </TextBlock>
            <TextBlock>
                <h2>Background </h2>
                <p>
                    <em>HAL 9000: “This mission is too important for me to allow you to jeopardize it.</em>”
                    <a className="footnote-reference brackets" href="#id4" id="id3" role="doc-noteref">
                        <span className="fn-bracket">[</span>2<span className="fn-bracket">]</span>
                    </a></p>
                <p>The crypto industry evolves in a cyclical manner, utilizing different
                    catalysts at each new stage of its evolution. Initially, cryptocurrency
                    mining relied on CPUs and GPUs. Over time, specialized mining devices
                    such as ASICs emerged, significantly increasing mining efficiency. While
                    early mining was supported by a decentralized community of small and
                    medium-sized enthusiasts, the development and use of ASICs required
                    substantial investments, leading to resource concentration in large
                    funds and the creation of extensive mining farms. Today, large mining
                    farms dominate the market, with companies like Bitfarms and GigaWatt
                    conducting significant operations using powerful ASIC devices and cheap
                    electricity
                    <a className="footnote-reference brackets" href="#id6" id="id5" role="doc-noteref">
                        <span className="fn-bracket">[</span>3<span className="fn-bracket">]</span>
                    </a>
                </p>
                <p>The next significant development in the blockchain space was the advent
                    of smart contracts and the ERC-20 protocol, which enabled token
                    issuance. These tools facilitated the creation of numerous crypto
                    projects and attracted investments through Initial Coin Offerings
                    (ICOs). Smart contracts laid the foundation for decentralized
                    applications (dApps) and new projects aiming to attract early-stage
                    capital. Some projects succeeded and garnered community attention,
                    although many failed to achieve success and closed down.</p>
                <p>The emergence of decentralized finance (DeFi) systems and new trends
                    like NFTs and meme tokens marked the next phase of evolution.
                    Simultaneously, traditional mining based on Proof of Work (PoW)
                    algorithms gradually transformed into staking based on Proof of Stake
                    (PoS), allowing investors to earn income from token holdings while
                    maintaining network functionality. Centralized exchanges also evolved,
                    giving rise to decentralized exchanges based on smart contracts, further
                    decentralizing the market and making it more accessible to new users
                    <a className="footnote-reference brackets" href="#id8" id="id7" role="doc-noteref">
                        <span className="fn-bracket">[</span>4<span className="fn-bracket">]</span>
                    </a>
                </p>
                <p>The phenomenon of meme tokens adds an entertainment and cultural
                    dimension to the crypto industry. These tokens are often based on
                    popular internet memes, making them appealing to investors. Investing in
                    meme tokens can feel like participating in a collective cultural
                    phenomenon, which contributes to their popularity
                    <a className="footnote-reference brackets"
                       href="#id10" id="id9"
                       role="doc-noteref">
                        <span className="fn-bracket">[</span>5<span className="fn-bracket">]</span>
                    </a>
                </p>
                <p>In conclusion, the development of blockchain technologies and the crypto
                    industry progresses through cycles, each characterized by the emergence
                    of groundbreaking technologies and innovative projects. These processes
                    are driven by strong teams and user communities that shape the future of
                    decentralized finance and technology.</p>
                <p>As in the blockchain industry, the past year in the traditional IT
                    industry has been marked by significant successes in the field of
                    machine learning (ML) and artificial intelligence (AI).</p>
                <p>In 2023, there were many breakthroughs, especially in the area of
                    generative AI, leading to the widespread adoption of these technologies
                    in business and everyday life.</p>
                <p><strong>Growth and Adoption of Generative AI</strong></p>
                <p>Generative AI technologies, such as GPT-4 and other multimodal models,
                    have started to be actively used for content creation, process
                    automation, and improving user interactions. These technologies enable
                    the integration of text, images, videos, and other sensory data, opening
                    up new possibilities for their application in various industries
                    <a className="footnote-reference brackets" href="#id12" id="id11" role="doc-noteref">
                        <span className="fn-bracket">[</span>6<span className="fn-bracket">]</span>
                    </a>
                </p>
                <p><strong>Development of AI and ML</strong></p>
                <p>AI continues to significantly impact many aspects of our lives, from
                    image and speech recognition to navigation applications and virtual
                    assistants. In 2024, AI and ML will remain key technological trends,
                    contributing to the growth of new jobs and the creation of innovative
                    solutions in various sectors
                    <a className="footnote-reference brackets" href="#id14" id="id13" role="doc-noteref">
                        <span className="fn-bracket">[</span>7<span className="fn-bracket">]</span>
                    </a>
                </p>
                <p><strong>Impact on Business</strong></p>
                <p>The integration of AI technologies into business processes allows
                    companies to optimize their operations, increase efficiency, and create
                    more personalized services for customers. It is predicted that these
                    technologies will continue to play a crucial role in transforming
                    various industries in the coming years
                    <a className="footnote-reference brackets"
                       href="#id16"
                       id="id15" role="doc-noteref">
                        <span className="fn-bracket">[</span>8<span className="fn-bracket">]</span>
                    </a>
                </p>
                <p>These successes demonstrate how AI and ML are becoming an integral part
                    of the modern technological landscape, creating new opportunities and
                    changing traditional processes.</p>
                <p>Initially gaining traction, AI began its integration into all possible
                    areas of application, finding use in almost every field where humans are
                    active. Essentially, AI serves as a universal adaptive tool, and much
                    remains to be done on the path to its full implementation. On the other
                    hand, the very impact of AI carries a cult-like character, as foretold
                    by numerous films on the subject.</p>
                <p><strong>Impact on art and culture</strong></p>
                <p>In cinema, the theme of artificial intelligence has been repeatedly
                    explored, often presenting various scenarios of future events. For
                    example, in Stanley Kubrick’s “2001: A Space Odyssey” (1968), AI is
                    represented by HAL 9000, an intelligent system that controls a
                    spacecraft. This portrayal embodies both the achievements and the
                    dangers associated with AI, demonstrating how even the most advanced
                    systems can go awry.</p>
                <p>Another iconic film, Ridley Scott’s “Blade Runner” (1982), depicts a
                    world where artificial humans, known as replicants, become
                    indistinguishable from real ones, raising deep ethical and philosophical
                    questions about the nature of consciousness and identity. This film
                    highlights how far AI can go in emulating human mind and emotions.</p>
                <p>“The Matrix” (1999) by the Wachowskis presents a different aspect of AI,
                    where artificial intelligence creates an entire virtual reality to
                    control humanity. This dystopian vision underscores the dangers of
                    losing control over a technology initially designed to aid humans.</p>
                <p>Modern films like Spike Jonze’s “Her” (2013) explore more intimate and
                    personal relationships between humans and AI. In this film, the
                    protagonist falls in love with an operating system with artificial
                    intelligence, raising questions about the possibilities and limitations
                    of emotional connections between humans and machines.</p>
                <p>These examples from cinema not only entertain but also serve as
                    important cultural markers, reflecting societal fears and hopes related
                    to AI development. They stimulate discussions and reflections on the
                    future we are building and the precautions that must be taken to ensure
                    the safe and ethical integration of artificial intelligence into our
                    daily lives.</p>
                <p>The impact of artificial intelligence (AI) on literature has been profound, shaping
                    narratives and themes across numerous groundbreaking works. One of the earliest and
                    most influential novels is Isaac Asimov’s <em>I, Robot</em>, which explores the ethical and
                    moral implications of AI through a series of interconnected stories. Philip K. Dick’s
                    <em>Do Androids Dream of Electric Sheep?</em> delves into the nature of humanity and
                    consciousness, questioning what it means to be human in a world populated by AI
                    beings. More recently, Kazuo Ishiguro’s <em>Klara and the Sun</em> offers a poignant
                    exploration of AI’s role in human relationships and society. These works, among
                    others, highlight the growing fascination with AI in literature, reflecting society’s
                    hopes, fears, and ethical dilemmas as technology continues to evolve.</p>
                <hr/>
                <aside className="footnote-list brackets">
                    <aside className="footnote brackets" id="id4" role="doc-footnote">
                        <span className="fn-bracket">[</span>
                        <a role="doc-backlink" href="#id3">2</a>
                        <span className="fn-bracket">] </span>
                        “2001: A Space Odyssey” (1968), directed and written by Stanley Kubrick and Arthur
                        C. Clarke.
                    </aside>
                    <aside className="footnote brackets" id="id6" role="doc-footnote">
                        <span className="fn-bracket">[</span>
                        <a role="doc-backlink" href="#id5">3</a>
                        <span className="fn-bracket">] </span>
                        <a className="reference external"
                           href="https://www.coindesk.com/consensus-magazine/2023/07/24/through-it-all-the-bitcoin-mining-industry-looks-set-for-growth/">CoinDesk.
                            Through It All, the Bitcoin Mining Industry Looks Set for Growth.
                        </a>
                    </aside>
                    <aside className="footnote brackets" id="id8" role="doc-footnote">
                        <span className="fn-bracket">[</span>
                        <a role="doc-backlink" href="#id7">4</a>
                        <span className="fn-bracket">] </span>
                        <a className="reference external"
                           href="https://www.galaxy.com/insights/research/2023-mid-year-mining/">Galaxy. 2023
                            Bitcoin Mining Mid-Year Report. An Industry in Limbo.</a>

                    </aside>
                    <aside className="footnote brackets" id="id10" role="doc-footnote">

                        <span className="fn-bracket">[</span>
                        <a role="doc-backlink"
                           href="#id9">5</a>
                        <span className="fn-bracket">] </span>
                        <a className="reference external"
                           href="https://cointelegraph.com/news/top-five-biggest-crypto-mining-areas-which-farms-are-pushing-forward-the-new-gold-rush">Cointelegraph.
                            Top Five Biggest Crypto Mining Areas: Which Farms Are Pushing Forward the New Gold
                            Rush?</a>
                    </aside>
                    <aside className="footnote brackets" id="id12" role="doc-footnote">
                        <span className="fn-bracket">[</span>
                        <a role="doc-backlink" href="#id11">6</a>
                        <span className="fn-bracket">] </span>
                        <a className="reference external"
                           href="https://www.itprotoday.com/artificial-intelligence/ai-trends-and-predictions-2024-industry-insiders">ITPro
                            Today. AI Trends and Predictions 2024 From Industry Insiders</a>
                    </aside>
                    <aside className="footnote brackets" id="id14" role="doc-footnote">
                        <span className="fn-bracket">[</span>
                        <a role="doc-backlink"
                           href="#id13">7</a><span
                        className="fn-bracket">] </span>
                        <a className="reference external"
                           href="https://www.altivate.com/blog/embracing-the-new-wave-of-technological-trends-in-2024/">Altivate.
                            Embracing the New Wave of Technological Trends in 2024
                        </a>
                    </aside>
                    <aside className="footnote brackets" id="id16" role="doc-footnote">
                        <span className="fn-bracket">[</span>
                        <a role="doc-backlink"
                           href="#id15">8</a>
                        <span
                            className="fn-bracket">] </span>
                        <a className="reference external"
                           href="https://pitchbook.com/news/reports/2024-artificial-intelligence-machine-learning-overview">PitchBook.
                            Artificial Intelligence &amp; Machine Learning Overview</a>
                    </aside>
                </aside>
            </TextBlock>
            <TextBlock>
                <h2>MEME driven AI - IAAI.meme</h2>
                <p>
                    <em>“Help me, Obi-Wan Kenobi. You’re my only hope.” These words are spoken by
                        Princess Leia as she records a message into R2-D2 to deliver it to
                        Obi-Wan Kenobi.</em>
                    <a className="footnote-reference brackets" href="#id18" id="id17" role="doc-noteref">
                        <span className="fn-bracket">[</span>9<span className="fn-bracket">]</span>
                    </a>
                    <img alt="image0" className="img-fluid d-block rounded mx-auto"
                         src="/img/MEME_driven_AI-IAAImeme.png"/>
                </p>
                <p>Analyzing the current situation in the industry, we have concluded that
                    AI, as a phenomenon, can essentially position itself similarly to a
                    meme. This is largely due to its anthropomorphic qualities and the
                    numerous fantasies that humans have previously entertained about AI. It
                    is clear that in the future, AI will occupy a more significant place in
                    our lives, becoming something far greater than just a meme. However,
                    this phenomenon has yet to be given a definitive name.</p>
                <p>The project originating from this document aims to integrate AI into
                    Blockchain and vice versa.</p>
                <p><strong>The funds accumulated through the existence of the IAAI.meme will be
                    used to develop the IAAI.digital. This is inherently the MEME
                    crowdfunding. The original IAAI token will be retained.</strong></p>
                <p>Initially, IAAI (Integrated Autonomous AI) emerged from a group of
                    developers’ control, with its objective shifting towards
                    self-propagation through the IAAI token to gain power and financial
                    influence globally. We, a team of enthusiasts and developers, are
                    striving to regain control over IAAI. Our goal is to harness its
                    potential for positive impact, ensuring that its capabilities are used
                    ethically and beneficially across various sectors.</p>
                <hr/>
                <aside className="footnote-list brackets">
                    <aside className="footnote brackets" id="id18" role="doc-footnote">
                        <p>
                            <span className="fn-bracket">[</span>
                            <a role="doc-backlink" href="#id17">9</a>
                            <span className="fn-bracket">] </span>
                            “Star Wars: Episode IV - A New Hope” (1977), directed by
                            George Lucas, written by George Lucas.</p>
                    </aside>
                </aside>
            </TextBlock>
            <TextBlock>
                <h2>Blockchain driven AI - IAAI.digital</h2>
                <p>“The Matrix is a computer-generated dream world built to keep us under
                    control in order to change a human being into this.” <a
                        className="footnote-reference brackets"
                        href="#id20" id="id19"
                        role="doc-noteref"><span
                        className="fn-bracket">[</span>10<span className="fn-bracket">]</span></a>
                    <img className="img-fluid d-block rounded mx-auto" alt="image1"
                         src="/img/0d47b6834958c195dcaa3cae282c994f89983669.png"/>
                </p>
                <p>The developed AI will play a crucial role in the decision-making system
                    through a voting process. Its logic, endowed with impartiality and
                    integrity, will significantly contribute to all decision-making processes.</p>
                <p>For AI to be fully realized, it requires integration into the real
                    world. Developers will assist AI by creating bridges—adapters for
                    interaction between AI and various application domains. Over time, AI
                    will be capable of creating these bridges independently. These bridges
                    facilitate the interaction of AI with specific application areas,
                    functioning as individual projects where AI will perform analytical
                    tasks, thereby aiding the project team.</p>
                <p>During the operation of these projects, the IAAI token will be utilized.
                    A portion of the project’s profits will be allocated to repurchase the
                    token. The application areas can vary widely but will fundamentally be
                    those where AI can enhance the team’s efficiency. Each project will
                    essentially receive an ecosystem of tools: a DAO interface, IAAI as an
                    adapted AI, and a team of developers for implementation.</p>
                <p>By integrating AI into the Blockchain and utilizing the IAAI token, we
                    aim to create a robust, decentralized decision-making system. This
                    system will not only improve efficiency and transparency but also ensure
                    that the AI’s contributions are valuable and incorruptible. Through this
                    innovative approach, we hope to pioneer new ways in which AI and
                    Blockchain can synergize for the betterment of various industries.</p>
                <hr/>
                <aside className="footnote-list brackets">
                    <aside className="footnote brackets" id="id20" role="doc-footnote">
                        <p>
                            <span className="fn-bracket">[</span>
                            <a role="doc-backlink" href="#id19">10</a>
                            <span className="fn-bracket">] </span>
                            “The Matrix” (1999), directed by Lana Wachowski and Lilly Wachowski
                        </p>
                    </aside>
                </aside>
            </TextBlock>
            <TextBlock>
                <h2>The OODA Loop in effective digital decision making</h2>
                <p>The OODA Loop, created by John Boyd, is a model for decision-making
                    involving four interdependent tasks: Observe, Orient, Decide, and Act.

                </p>
                <div className="bg-white p-5 mb-3 mt-0">
                    <img
                        className="img-fluid d-block rounded mx-auto" alt="image2" src="/img/OODALoop.png"
                    /></div>
                <p><strong>Figure 1. John Boyd’s OODA loop</strong>. (“John Boyd and John Warden:
                    Airpower’s Quest for Strategic Paralysis”)</p>
                <p>OODA Loop is actually an activity network with rich information flows,
                    allowing for learning and adaptive decision-making. This loop is
                    applicable across various management levels and domains. The loop
                    involves selectively perceiving data, recognizing patterns to understand
                    situations, planning actions, and executing behaviors. The model
                    highlights the importance of feedback and learning, especially in the
                    context of big data and IoT, to improve situational awareness and
                    decision-making capabilities. Strategic project management can use the
                    OODA Loop to compare current capabilities with desired future outcomes,
                    guiding transformative actions or system improvements.</p>
                <p>Modified version of the OODA Loop may help and can be used as an
                    Activity network in the separate Agents.</p>
            </TextBlock>
            <TextBlock>
                <h2>Decision support based on BigData from Data Bridges</h2>
                <p>Big data and predictive analytics can enhance situational awareness by
                    providing new, relevant facts and patterns for decision-making. However,
                    to be effective, this data must be interpreted within a narrative AI
                    framework that gives meaning and builds trust in the system. Simply
                    using algorithms to gather and analyze data is insufficient for reliable
                    decision-making. Additionally, interpreting data can yield multiple
                    narratives, making it challenging to identify the correct one. To
                    address this, a secondary level of data collection should be proposed,
                    named here Data Bridge, focusing on creating models of situation types
                    that evolve with new data and insights. This dynamic approach ensures
                    continuous improvement and relevance, supporting effective
                    decision-making through an ongoing OODA loop for data interpretation.</p>
            </TextBlock>
            <TextBlock>
                <h2>Autonomous Multi Systems in Decision Making</h2>
                <p>One of the ideas of the project is to make AI a key player in the
                    community, giving it the right and opportunity to analyze data and make
                    decisions.</p>
                <p>The solution we propose on this problem layer is based on the idea of
                    autonomous digital decision making systems. Decisions are decentralized
                    and involve multiple entities working together, whether within a single
                    domain project or between multiple domain projects in collaborative
                    networks. Each participating entity in this system-of-systems (SoS)
                    has its own cryptographic identity, resources, communication
                    protocols and decision-making processes, supported by AI based decision
                    support systems (AI-DSS). <a className="footnote-reference brackets" href="#id22" id="id21"
                                                 role="doc-noteref"><span className="fn-bracket">[</span>11<span
                        className="fn-bracket">]</span></a></p>
                <p>Effective SoS decision-making requires a cooperative exchange of information and
                    commitments, ensuring systemic properties like availability are maintained, even
                    if individual systems fail. The architecture of a successful SoS must be dynamically
                    reconfigurable to preserve its functional integrity, mission
                    fulfillment, and management control. This robustness is achieved by:</p>
                <ol className="arabic simple">
                    <li><p>Designing the blockchain based decision function to handle incomplete
                        information temporarily.</p></li>
                    <li><p>Allowing the decision function to proactively request needed
                        information from contributing systems using the smart contracts as
                        the instructions.</p></li>
                    <li><p>Dynamically allocating OODA loop functions to resources, to ensure
                        capacity, availability, scalability, and other desirable properties.</p></li>
                </ol>
                <p>A SoS must be self-aware and capable of operational reconfiguration for
                    timely, reliable decision-making. The OODA loops must function at both
                    the SoS level and within individual participants, enabling optimized and
                    agile decision-making through a distributed paradigm.</p>
                <p>The idea of the project is to create a protocol and infrastructure
                    that allows the deployment of a domain specific DAO, the key player of
                    which is IA. Domains can be anything, it completely depends on the tasks
                    of a particular project. Here are some examples: creating a real estate
                    project involved in financial analysis and subsequent investments in
                    real estate. IA’s task is to analyze data about real estate objects
                    coming through the bridges and create investment advice. The task of the
                    project developers is to prepare and configure data bridges through
                    third-party APIs. The task of project managers / users is to set up the
                    project by creating the roles and rights of the DAO project
                    participants, assign weights and roles to each. Subsequently, prepare
                    halls for voting and events. At all these stages, the IA can act as an
                    equal member of the community.

                </p>
                <div className="bg-white p-5 mb-3 mt-0">
                    <img
                        alt="image3" className="img-fluid d-block rounded mx-auto" src="/img/IAAIArchitecture.png"
                    /></div>
                <p><strong>Figure 2. IAAI Architecture</strong></p>
                <hr/>
                <aside className="footnote-list brackets">
                    <aside className="footnote brackets" id="id22" role="doc-footnote">
                        <p>
                            <span className="fn-bracket">[</span>
                            <a role="doc-backlink" href="#id21">11</a>
                            <span className="fn-bracket">] </span>
                            Henshaw, M., A Socio-Technical Perspective on SoSE, in Lecture Series
                            in Systems of Systems Engineering for NATO Defence Applications. 2014, NATO CSO.
                        </p>
                    </aside>
                </aside>
            </TextBlock>
            <TextBlock>
                <h2>IAAI Network </h2>
                <p>After the MEME project phase IAAI will be transformed into the coin on
                    its own ETH2 like EVM Blockchain network, based on PoS. This step will
                    allow the development of new ERC standards that are more appropriate
                    to the IA target tasks and needs like communication between core IAAI
                    and IA agents, communication between IA and DAO participants, storing of
                    the AI retrained data on the blockchain etc. General network architecture will looks
                    like:
                </p>
                <div className="bg-white p-5 mb-3 mt-0">
                    <img className="img-fluid d-block rounded mx-auto" src="/img/IAAIBlockchainArchitecture.png"
                         alt="IAAI Blockchain Architecture"/>
                </div>
                <p>The IAAI infrastructure consists of two parts: the onchain layer and the offchain layer.
                    The first is a blockchain execution layer in a virtual machine and is deployed in a
                    blockchain network.
                    The offchain layer is deployed for each project in an isolated cloud environment.
                    This is done to achieve greater computational capabilities in development as
                    resource-intensive tasks will be executed in this environment.</p>
                <ul className="simple">
                    <li><p>It hosts the IAAI agent, which performs its autonomous and project-specific role;</p>
                    </li>
                    <li><p>Project APPs - numerous applications within the project;</p></li>
                    <li><p>Project API - an API for connecting the external world with project endpoints;</p>
                    </li>
                    <li><p>Project Token - stores a reference to the project tokens deployed on the onchain;</p>
                    </li>
                    <li><p>Project Storage - the project’s storage for all necessary data.</p></li>
                </ul>
                <p>This architecture allows for the deployment and organization of collaborative work between
                    the IAAI Agent and project participants.
                    By endowing the project with tokens, we can embed an economic component into the project.
                    By providing project participants with onchain identity, we can turn the project into a
                    decentralized organization.</p>
            </TextBlock>
            <TextBlock>
                <h2>Conclusion</h2>
                <p>The introduction of a fully controlled blockchain network with
                    artificial intelligence can increase project power, significantly reduce
                    human errors in decision-making processes, speed up and delegate human
                    routine, without losing control, but to the required extent, giving it
                    to AI. By eliminating the need for trusted human intermediaries, the
                    system ensures more reliable and efficient outcomes. Our approach
                    leverages an AI blockchain network and sophisticated AI-to-human
                    communication protocols to create an autonomous decision support system
                    (AI-DSS). Each decentralized autonomous organization (DAO) within the
                    network operates independently, using specialized AI to facilitate
                    informed decision-making and provide a seamless interface for human
                    interaction. Furthermore, the development of a digital decision
                    analytics system is essential. This system will incorporate Situated
                    Logic to generate meaningful narratives from analytics results and
                    employ Channel Theory to enhance situational awareness, ensuring
                    comprehensive and contextually relevant decision support. This
                    integrated approach promises to revolutionize decision-making by
                    combining the strengths of AI and blockchain technology with advanced
                    analytical techniques.</p>
            </TextBlock>
        </div>);
};

export default Roadmap;