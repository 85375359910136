const config = {
    botName: "IA AI",
    customStyles: {
        botMessageBox: {
            backgroundColor: "#9256DC",
        },
        chatButton: {
            backgroundColor: "#77797b",
        },
    },
    customComponents: {
        header: () => (
            <div style={{ padding: '0px', borderRadius: '10px 10px 0 0', color: '#fff' }}></div>
        ),
        botAvatar: (props) => (
            <div
                style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundColor: '#9256DC',
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginRight: '10px'
                }}
                {...props}
            >
                IA
            </div>
        )
    },
};

export default config;