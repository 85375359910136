import React, { useEffect, useState } from 'react';

const TypingAnimationMessage = ({ message }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index < message.length) {
            const timeoutId = setTimeout(() => {
                setDisplayedText((prev) => prev + message[index]);
                setIndex((prev) => prev + 1);
            }, 30); // typing speed (adjust if needed)

            return () => clearTimeout(timeoutId);
        } else {
            // Scroll to bottom once typing is complete
            const chatMessageContainer = document.querySelector('.react-chatbot-kit-chat-message-container');
            if (chatMessageContainer) {
                chatMessageContainer.scrollTop = chatMessageContainer.scrollHeight;
            }
        }
    }, [index, message]);

    return <div>{displayedText}</div>;
};

export default TypingAnimationMessage;