import {Col, Container, Row} from "react-bootstrap";

const Title = ({children}) => {
    return(
        <Container className="mt-4">
            <Row className="m-2">
                <Col className="p-2" style={{backgroundColor: 'rgba(248, 249, 250, 0.6)'}}>
                    <h1 className="text-center text-primary-emphasis">{children}</h1>
                </Col>
            </Row>
        </Container>
    )

}
export default Title