import {Outlet} from "react-router-dom";
import { SocialIcon } from 'react-social-icons'

import Menu from "./Menu";
import {Container, Row, Col} from "react-bootstrap";
const MainLayout = () => {


    return (
        <>
            <Menu/>
            <Outlet/>
            <footer className="bg-black bg-opacity-75 text-center">
                <Container>
                    <Row>
                        <Col>
                            <ul className="list-inline p-4 pb-0">
                                <li className="list-inline-item"><SocialIcon bgColor="#9256DC" url="https://x.com/IAAIRobot" target="_blank" /></li>
                                <li className="list-inline-item"><SocialIcon bgColor="#9256DC" url="http://github.com/iaai-meme/" target="_blank"/></li>
                                <li className="list-inline-item"><SocialIcon bgColor="#9256DC" url="https://www.instagram.com/iaairobot" target="_blank"/></li>
                                <li className="list-inline-item"><SocialIcon bgColor="#9256DC" url="https://t.me/iaairobotchannel" target="_blank"/></li>
                            </ul>
                        </Col>
                    </Row>

                </Container>


                <div className="text-center p-3 text-white">
                    © 2024 Copyright: <a className="text-white" href="https://iaairobot.com/">IAAI Robot</a>
                </div>
            </footer>
        </>
    )
}

export default MainLayout
