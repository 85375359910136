import axios from "axios";
import TypingAnimationMessage from './components/TypingAnimationMessage';

class ActionProvider {
    constructor(createChatBotMessage, setStateFunc, createClientMessage, stateRef, createCustomMessage, ...rest) {
        this.createChatBotMessage = createChatBotMessage;
        this.setState = setStateFunc;
        this.createClientMessage = createClientMessage;
        this.stateRef = stateRef;
        this.createCustomMessage = createCustomMessage;
        this.apiUrl = process.env.REACT_APP_OPENAI_API_URL;
        this.messageHistoryDepth = parseInt(process.env.REACT_APP_MESSAGE_HISTORY_DEPTH) || 10;
    }

    getLastMessages() {
        const messages = JSON.parse(sessionStorage.getItem("chatMessages")) || [];
        return messages.slice(-this.messageHistoryDepth);
    }

    saveMessages(messages) {
        const standardizeMessage = (message) => {
            if (typeof message.message === 'object' && message.message.props) {
                return {
                    message: message.message.props.message,
                    type: message.type,
                    id: message.id
                };
            } else {
                return {
                    message: message.message,
                    type: message.type,
                    id: message.id
                };
            }
        };

        const standardizedMessages = messages.map(standardizeMessage);

        sessionStorage.setItem("chatMessages", JSON.stringify(standardizedMessages));
    }

    scrollToBottom() {
        const chatMessageContainer = document.querySelector('.react-chatbot-kit-chat-message-container');
        if (chatMessageContainer) {
            chatMessageContainer.scrollTop = chatMessageContainer.scrollHeight;
        }
    }

    async handleMessage(message) {
        this.setState(prev => ({
            ...prev,
            typing: true
        }));

        const lastMessages = this.getLastMessages();

        const formattedMessages = lastMessages.map((msg) => ({
            role: msg.type === "bot" ? "assistant" : "user",
            content: msg.message,
        }));

        formattedMessages.push({ role: "user", content: message });

        try {
            const response = await axios.post(
                this.apiUrl,
                {
                    messages: formattedMessages,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    timeout: 60000
                }
            );

            const botMessage = this.createChatBotMessage(
                <TypingAnimationMessage message={response.data.choices[0].message.content} />,
                { withAvatar: true }
            );

            this.setState(prev => ({
                ...prev,
                typing: false
            }));

            this.setChatbotMessage(botMessage);

            this.scrollToBottom();

            this.saveMessages([...lastMessages, { type: "user", message }, { type: "bot", message: response.data.choices[0].message.content }]);
        } catch (error) {
            const errorMessage = this.createChatBotMessage("Error: request processing", { withAvatar: true });

            this.setState(prev => ({
                ...prev,
                typing: false
            }));

            this.setChatbotMessage(errorMessage);

            this.scrollToBottom();

            this.saveMessages([...lastMessages, { type: "user", message }, { type: "bot", message: "Error: request processing" }]);
        }
    }

    setChatbotMessage(message) {
        this.setState((prev) => {
            const newMessages = [...prev.messages, message];
            this.saveMessages(newMessages);

            this.scrollToBottom();

            return {
                ...prev,
                messages: newMessages,
            };
        });
    }
}

export default ActionProvider;