import Title from "./Title";
import TextBlock from "./TextBlock";

const Roadmap = () => {
    return (
        <div className="d-flex flex-column min-vh-100">
            <Title>Roadmap</Title>
            <TextBlock>
                <h3>2024 (Q2-Q3) — Project start</h3>
                <ul>
                    <li>Idea generation and presentation at the conference <a
                        href="https://blockchainweek.berlin/24">https://blockchainweek.berlin/24</a></li>
                    <li>Research and whitepaper</li>
                    <li>Team composition,</li>
                    <li>Requirements development, UI/UX, 3D Design</li>
                    <li>PoW Telegram MiniAPP Developing, TON Smart Contracts</li>
                </ul>

                <h3>2024 (Q4) — Launch of the first Version with Basic Features and Evolution Level 1</h3>
                <ul>
                    <li>All robot characteristics (CPU, RAM, GPU, Storage) are available but cannot be
                        upgraded until a certain evolution level.
                    </li>
                    <li>Users can train their robots, develop their intelligence (IQ) through interactions,
                        and level up their IQ.
                    </li>
                    <li>Introduction of core mechanics: battery energy, XP (experience), and IQ levels.</li>
                    <li>Robot progression to Evolution Level 1, with the option to use cryptocurrency for
                        evolution.
                    </li>
                    <li>Special tasks are available only for users who reach certain IQ levels. Introduction
                        of rewards (drops) for early testers.
                    </li>
                </ul>

                <h3>2025 (Q1) — Introduction of NFT and Ability to Upgrade Robot Characteristics</h3>
                <ul>
                    <li>Introduction of the NFT mechanic: users can buy NFTs for robots. Opening the
                        possibility of upgrading robot characteristics (CPU, RAM, GPU, Storage) via NFTs.
                    </li>
                    <li>Robot progression to Evolution Level 2, with access to new slots for NFTs.</li>
                </ul>

                <h3>2025 (Q2) — Adding New Crypto Networks and Expanding the User Base</h3>
                <ul>
                    <li>Introduce NFT Marketplace where users can sell robot tooling as NFTs</li>
                    <li>Personalized robots - users change the appearance of the robot through communication.</li>
                    <li>Adding new cryptocurrency networks to support a wider audience.</li>
                </ul>

                <h3>2025 (Q3) — Introduction of Personal Assistant Functionality</h3>
                <ul>
                    <li>The robot becomes a personal assistant and add external GPT services</li>
                    <li>Users can customize the robot-assistant's features according to their needs.</li>
                </ul>

                <h3>2025 (Q4) — Release of Mobile App and New Robot Versions</h3>
                <ul>
                    <li> Release of the mobile app for iOS and Android platforms.</li>
                    <li>Introduction of new robot versions with unique designs and features, available via NFTs.
                    </li>
                </ul>

                <h3>2026 (Q1) — Development of Clan System and Expansion of NFTs</h3>
                <ul>
                    <li>Creation of clans for team-based gameplay.</li>
                    <li>Robot progression to Evolution Level 4 with opportunities for team collaboration.</li>
                    <li>Expanded customization options through unique NFTs.</li>
                </ul>

                <h3>2026 (Q3) — Introduction of Global Map and First Clan Battles</h3>
                <ul>
                    <li>Introduction of the global map, where clans can compete for resources and participate in
                        battles.
                    </li>
                    <li>Robot progression to Evolution Level 5, with new collaboration opportunities.</li>
                </ul>

                <h3>2027 (Q1) — Full Robot Battle Functionality and Clan-Based Economy</h3>
                <ul>
                    <li>Full robot battle functionality on the global map, including team interactions and an
                        economy based on NFTs.
                    </li>
                </ul>

                <h3>2027 (Q2 and Beyond) — Large-Scale DePIN Integration</h3>
                <ul>
                    <li> Release of physical toys that synchronize with the app, offering bonuses and a unique
                        experience.
                    </li>
                </ul>
                Large-scale DePIN integration includes:
                <ul>
                    <li>Creation of physical toys based on your personal robot.</li>
                    <li>Release of a dedicated device that supports your personal robot.</li>
                </ul>
                These devices will synchronize with the app, providing additional features and a unique
                user experience.

            </TextBlock>
        </div>
    )

}
export default Roadmap