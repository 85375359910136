import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import {Container} from "react-bootstrap";
import {NavLink} from "react-router-dom";

const Menu = () => {


    return (
        <Navbar expand="lg" className="bg-opacity-75"  bg="black" data-bs-theme="dark">
            <Container>
                <Navbar.Brand  as={NavLink} to="."><img
                    src="/logo.png"
                    height="50"
                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={NavLink} to=".">Home</Nav.Link>
                        <Nav.Link as={NavLink} to="chat">Chat with IA</Nav.Link>
                        <Nav.Link as={NavLink} to="whitepaper">Whitepaper</Nav.Link>
                        <Nav.Link as={NavLink} to="roadmap">Roadmap</Nav.Link>
                        <Nav.Link as={NavLink} to="team">Team</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Menu