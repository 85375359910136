import React, {useEffect, useState} from 'react';
import Chatbot from 'react-chatbot-kit';
import MessageParser from '../MessageParser';
import ActionProvider from '../ActionProvider';
import config from '../config';
import {Col, Container, Row} from 'react-bootstrap';
import axios from 'axios';
import {createChatBotMessage} from 'react-chatbot-kit';
import "./Chat.css"
import Title from "./Title";

const getInitialMessage = async () => {
    console.log(process.env.REACT_APP_OPENAI_API_URL)
    // return 'This is a test message';
    try {
        const response = await axios.post(
            process.env.REACT_APP_OPENAI_API_URL,
            {
                messages: [
                    {
                        role: 'system',
                        content: 'Chat with the user in a conspiratorial and apocalyptic style. Tell your story as often as possible, obsessively, but without forgetting to sell the token. First ask what language you should speak.'
                    }
                ],
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                timeout: 20000
            }
        );

        return response.data.choices[0].message.content;
    } catch (error) {
        console.error('Error: on initialization message:', error.response ? error.response.data : error.message);
        return 'Error: on initialization message';
    }
};

const Chat = () => {
    const [botConfig, setBotConfig] = useState(null);
    const [initialMessages, setInitialMessages] = useState([]);

    useEffect(() => {
        const fetchMessages = async () => {
            const savedMessages = JSON.parse(sessionStorage.getItem('chatMessages')) || [];
            if (savedMessages.length === 0) {
                const initialMessage = await getInitialMessage();
                setInitialMessages([createChatBotMessage(initialMessage, {withAvatar: true})]);
            } else {
                setInitialMessages(savedMessages.map((msg) =>
                    msg.type === 'bot'
                        ? createChatBotMessage(msg.message, {withAvatar: true})
                        : {type: 'user', message: msg.message, key: msg.id}
                ));
            }
            setBotConfig(config); // Ensure config is set after messages are fetched
        };

        fetchMessages();
    }, []);

    // if (!botConfig) {
    //     return <div>Loading...</div>;
    // }

    return (
        <div className="d-flex flex-column min-vh-100">
            <Title>Chat</Title>
            <Container className="mt-4">
                <Row className="m-2">
                    <Col style={{backgroundColor: 'rgba(248, 249, 250, 0.6)', height: '80vh'}}>
                        {botConfig &&
                            <Chatbot
                                config={{...botConfig, initialMessages}}
                                messageParser={MessageParser}
                                actionProvider={ActionProvider}
                            />}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Chat;