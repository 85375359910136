import {Container, Row, Col} from 'react-bootstrap';
const TextBlock = ({children}) => {
  return(
      <Container className="mt-4" >
          <Row className="m-2">
              <Col className="p-5" style={{backgroundColor: 'rgba(248, 249, 250, 0.6)'}}>
                  {children}
              </Col>
          </Row>
      </Container>
  )
}
export default TextBlock