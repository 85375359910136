import {Card, Col, Container, Row} from "react-bootstrap";
import {SocialIcon} from 'react-social-icons'
import Title from "./Title";
import TextBlock from "./TextBlock";

const Team = () => {
    return (
        <div className="d-flex flex-column min-vh-100">
            <Title>Team</Title>
            <TextBlock>
                <Container>
                    <Row>
                        <Col lg={4} md={6} className="pb-5">
                            <Card className="text-center">
                                <div className="d-flex flex-column align-items-center text-center">
                                    <img src="/img/team/Vladi.jpg" alt="Vladi"
                                         className="rounded mt-3" width="110"/>
                                </div>
                                <Card.Body>
                                    <Card.Title>Vladi</Card.Title>
                                    <Card.Subtitle>CHIEF EXECUTIVE OFFICER</Card.Subtitle>
                                </Card.Body>
                                <Card.Footer className="text-center">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <SocialIcon bgColor="#9256DC" url="https://www.linkedin.com/in/umatomba/"
                                                        target="_blank"/>
                                        </li>
                                        <li className="list-inline-item">
                                            <SocialIcon bgColor="#9256DC" url="https://x.com/umatomba" target="_blank"/>
                                        </li>
                                        <li className="list-inline-item">
                                            <SocialIcon bgColor="#9256DC" url="https://github.com/umatomba"
                                                        target="_blank"/>
                                        </li>
                                    </ul>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} className="pb-5">
                            <Card className="text-center">
                                <div className="d-flex flex-column align-items-center text-center">
                                    <img src="/img/team/Tim.jpg" alt="Tim"
                                         className="rounded mt-3" width="110"/>
                                </div>
                                <Card.Body>
                                    <Card.Title>Tim</Card.Title>
                                    <Card.Subtitle>CHIEF TECHNOLOGY OFFICER</Card.Subtitle>
                                </Card.Body>
                                <Card.Footer className="text-center">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <SocialIcon bgColor="#9256DC" url="https://linkedin.com/"
                                                        href="https://www.linkedin.com/in/timsolov/" target="_blank"/>
                                        </li>
                                        <li className="list-inline-item">
                                            <SocialIcon bgColor="#9256DC" url="https://github.com/"
                                                        href="https://github.com/timsolov"
                                                        target="_blank"/>
                                        </li>
                                    </ul>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} className="pb-5">
                            <Card className="text-center">
                                <div className="d-flex flex-column align-items-center text-center">
                                    <img src="/img/team/Max.jpg" alt="Max"
                                         className="rounded mt-3" width="110"/>
                                </div>
                                <Card.Body>
                                    <Card.Title>Max</Card.Title>
                                    <Card.Subtitle>SENIOR SOFTWARE ENGINEER</Card.Subtitle>
                                </Card.Body>
                                <Card.Footer className="text-center">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <SocialIcon bgColor="#9256DC" url="https://github.com/thebrain-dev"
                                                        target="_blank"/>
                                        </li>
                                    </ul>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} className="pb-5">
                            <Card className="text-center">
                                <div className="d-flex flex-column align-items-center text-center">
                                    <img src="/img/team/Sergej.jpg" alt="Sergej"
                                         className="rounded mt-3" width="110"/>
                                </div>
                                <Card.Body>
                                    <Card.Title>Sergej</Card.Title>
                                    <Card.Subtitle>CHIEF PRODUCT OFFICER</Card.Subtitle>
                                </Card.Body>
                                <Card.Footer className="text-center">
                                    <ul className="list-inline">
                                        <li className="list-inline-item"><SocialIcon bgColor="#9256DC"
                                                                                     url="https://www.linkedin.com/in/sfelde/"
                                                                                     target="_blank"/>
                                        </li>
                                        <li className="list-inline-item"><SocialIcon bgColor="#9256DC"
                                                                                     url="https://github.com/sfelde/"
                                                                                     target="_blank"/>
                                        </li>
                                    </ul>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} className="pb-5">
                            <Card className="text-center">
                                <div className="d-flex flex-column align-items-center text-center">
                                    <img src="/img/team/Albert.jpg" alt="Albert"
                                         className="rounded mt-3" width="110"/>
                                </div>
                                <Card.Body>
                                    <Card.Title>Albert</Card.Title>
                                    <Card.Subtitle>STRATEGY & PRODUCT MANAGER</Card.Subtitle>
                                </Card.Body>
                                <Card.Footer className="text-center">
                                    <ul className="list-inline">
                                        <li className="list-inline-item"><SocialIcon bgColor="#9256DC"
                                                                                     url="https://www.linkedin.com/in/albert-ergart-801899212/"
                                                                                     target="_blank"/>
                                        </li>
                                        <li className="list-inline-item"><SocialIcon bgColor="#9256DC"
                                                                                     url="https://x.com/AlbertErgart"
                                                                                     target="_blank"/></li>
                                        <li className="list-inline-item"><SocialIcon bgColor="#9256DC"
                                                                                     url="https://t.me/Albertos360"
                                                                                     target="_blank"/></li>
                                    </ul>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} className="pb-5">
                            <Card className="text-center">
                                <div className="d-flex flex-column align-items-center text-center">
                                    <img src="/img/team/Daria.jpg" alt="Daria"
                                         className="rounded mt-3" width="110"/>
                                </div>
                                <Card.Body>
                                    <Card.Title>Daria</Card.Title>
                                    <Card.Subtitle>UI/UX DESIGNER</Card.Subtitle>
                                </Card.Body>
                                <Card.Footer className="text-center">
                                    <ul className="list-inline">
                                        <li className="list-inline-item"><SocialIcon bgColor="#9256DC"
                                                                                     url="https://www.linkedin.com/in/daria-kanana/"
                                                                                     target="_blank"/></li>
                                    </ul>
                                </Card.Footer>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} className="pb-5">
                            <Card className="text-center">
                                <div className="d-flex flex-column align-items-center text-center">
                                    <img src="/img/team/img.png" alt="Alex"
                                         className="rounded mt-3" width="110"/>
                                </div>
                                <Card.Body>
                                    <Card.Title>Alex</Card.Title>
                                    <Card.Subtitle>3D DESIGNER</Card.Subtitle>
                                </Card.Body>
                                <Card.Footer className="text-center">
                                    <ul className="list-inline">
                                        <li className="list-inline-item"><SocialIcon bgColor="#9256DC"
                                                                                     url="https://www.linkedin.com/"
                                                                                     target="_blank"/></li>
                                    </ul>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </TextBlock>
        </div>
    )

}
export default Team
