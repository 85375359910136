import {Container, Row, Col} from 'react-bootstrap';
import Robot from "./Robot";
import TextBlock from "./TextBlock";


const Home = () => {
    return (
        <div className="d-flex flex-column">
            <TextBlock>
                <Robot/>
            </TextBlock>
        </div>
    );
};

export default Home;